// No official types for this at the time of writing.
type MedalliaClientSDK = {
  closeForm: (value: string) => void;
  loadForm: (formId: string) => void;
  showForm: (formId: string, options: Record<string, string>) => void;
  updatePageView: () => void;
};

declare global {
  interface Window {
    KAMPYLE_ONSITE_SDK?: MedalliaClientSDK;
  }
}

export const inject = (id: number): void => {
  const isNotLoaded = typeof window.KAMPYLE_ONSITE_SDK === "undefined";

  if (isNotLoaded) {
    const src = `https://resources.digital-cloud.medallia.eu/wdceu/${id}/onsite/embed.js`;

    const script = document.createElement("script");
    script.async = true;
    script.src = src;

    document.head.appendChild(script);
  }
};

export const virtualPageView = (): void => {
  if (window.KAMPYLE_ONSITE_SDK && typeof window.KAMPYLE_ONSITE_SDK.updatePageView === "function") {
    window.KAMPYLE_ONSITE_SDK.updatePageView();
  }
};

export const medalliaClient = (): MedalliaClientSDK | undefined => {
  return window.KAMPYLE_ONSITE_SDK;
};
